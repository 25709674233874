import axios from './axiosInstance';

export function getAllBanners() {
    return axios.get('/banners');
}

export function postBanner(data){
    return axios.post('/banners',data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

export function postEditBanner(data){
    return axios.post('/banners',data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

export function getBannerById(id){
    return axios.get('/banners/'+id);
}
export function deleteBanner(banner){
    return axios.delete('/banners', { data: {id:banner.id} });
}