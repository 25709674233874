import { useState } from 'react';

export default function useErrors() {
    const [errors, setErrors] = useState([]);

    function handleErrors(error, setError) {
        const data = error.response.data;
        
        if (!data) {
            setError("general", "error", "Algo salió mal. Vuelve a intentarlo.")
        } else if (typeof data.message === 'string') {
            setError("general", "error", data.message);
        } else {
            for (let key in data.message) {
                setError(key, "error", data.message[key])
            }
        }
    }

    return { errors, setErrors, handleErrors };
}