import React, { useState } from 'react';
import { TextInput, Button } from 'react-materialize';
import { connect } from 'react-redux';

import { login } from '../../redux/actions'

const loginForm = {
    email: '',
    password: ''
}

function Login({ onLogin, history }) {
    const [data, setData] = useState(loginForm);
    const [error, setError ] = useState('');
 
    function handleChange(event) {
        const { target } = event;
        setData(prevState => ({ ...prevState, [target.id]: target.value }))
    }

    function submitLogin(event) {
        event.preventDefault();
        onLogin(data.email, data.password, setError, history);
    }

    return (
        <section className="container">
            <div className="row"
            style={{
                marginTop: '3em'
                }}>
                <form onSubmit={submitLogin} className="col s6 offset-s3">
                    <TextInput
                        s={12}
                        label="Email"
                        id="email"
                        email
                        value={data.username}
                        onChange={handleChange}
                    />
                    <TextInput
                        s={12}
                        label="Contraseña"
                        id="password"
                        value={data.password}
                        password
                        onChange={handleChange}
                    />
                    <p>{error}</p>
                    <Button node="button" >
                        Iniciar sesión
                    </Button>
                </form>
                
            </div>
        </section>
    );
};


const mapDispatchToProps = dispatch => ({
    onLogin: (email, password, setError, history) => dispatch(login(email, password, setError, history))
});

export default connect(null, mapDispatchToProps)(Login);