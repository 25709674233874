import React from 'react'

function SuccessRegistration() {
    return (
    <section className="container">
        <div className="row">
            <h2>Su registro fue exitoso!</h2>
        </div>
    </section>
    );
}

export default SuccessRegistration;