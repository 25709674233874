import React from 'react'

function InputField({ register, errors, required, min, minMessage, name, placeholder, type, validate,value }) {
    if (!type) {
        type = "text";
    }


    return (<div className="input-field col s12">
        <input placeholder={placeholder} name={name} value={value}
            ref={register({
                required: required,
                minLength: { value: min, message: minMessage }, validate: validate
            })}
            type={type} className="validate" />
        {errors[name] &&
            <span className="helper-text">
                {errors[name].message}
            </span>}
    </div>
    )
}

export default InputField;