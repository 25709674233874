import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Router from '../Router/Router';
import Layout from '../Layout/Layout';
import Loader from '../common/Loader';
import { getCurrentUser } from '../../redux/actions';

import './App.css';
import 'materialize-css/dist/css/materialize.min.css';

function App() {
  const { auth, layout } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch])

  return (
    <BrowserRouter>
      <div className="App">
        {layout.initializing ?
          <Loader /> :
          <Layout>
            <Router auth={auth} />
          </Layout>
        }
      </div>
    </BrowserRouter>
  );
}


export default App;
