import Home from '../Home';
import Registration from '../Registration';
import SuccessRegistration from '../Registration/SuccessRegistration';
import Login from '../Login';
import paths from '../../constants/paths';
import BannerNew from '../Banner/BannerNew';
import BannerEdit from '../Banner/BannerEdit';
import Notifications from '../Notifications';
import CreateNotifications from '../CreateNotifications';

export default {
    authenticated: [
        {
            path: paths.home,
            exact: true,
            component: Home
        },
        {
            path: paths.bannernew,
            component: BannerNew
        },
        {
            path: paths.banneredit,
            component: BannerEdit
        },
        {
            path: paths.notifications,
            exact: true,
            component: Notifications
        },
        {
            path: paths.notificationsCreate,
            exact: true,
            component: CreateNotifications
        }
    ],
    unauthenticated: [
        {
            path: paths.home,
            exact: true,
            component: Login
        },
        {
            path: paths.successRegistration,
            component: SuccessRegistration
        },
        {
            path: paths.registration,
            component: Registration
        }

    ]
}



