import React from 'react';
import { Navbar, Icon } from 'react-materialize';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import paths from '../../../constants/paths'
import { logout } from '../../../redux/actions'

function Navigation({ onLogout, auth }) {


    return (
        <Navbar
            alignLinks="right"
            brand={<Link className="brand-logo" to="/">Afull</Link>}
            id="mobile-nav"
            menuIcon={<Icon>Menu</Icon>}
            options={{
                draggable: true,
                edge: 'left',
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 200,
                preventScrolling: true
            }}
        >
            {auth && <Link to={paths.home} > Home </Link>}
            {auth && <Link to={paths.notifications} > Notificaciones </Link>}
            {auth && <Link onClick={onLogout} className="nav-link">Cerrar sesión</Link>}
        </Navbar>
    );
}
const mapStateToProps = state => ({
    auth: state.auth
})

const mapDispatchToProps = dispatch => ({
    onLogout: (history) => dispatch(logout(history))
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

