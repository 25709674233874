import moment from 'moment';
import React from 'react';

const TableRow = ({ data }) => {
	return (
		<tr>
			<td>{data.title}</td>
			<td>{data.body}</td>
			<td>{moment(data.createdAt).format('DD / M / YYYY') }</td>
		</tr>
	);
};

export default TableRow;
