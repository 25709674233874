import * as actions from '../actionsTypes/banner';


export default (state = {}, { type, payload }) => {
    switch (type) {
        case actions.GET_ALL_BANNER_DATA: return payload;
        case actions.GET_BANNER_DATA: return payload;
        case actions.DELETE_BANNER:{
            return state.filter(banner => banner.id !== payload.banner.id);
        } 
        default: return state;
    }
}