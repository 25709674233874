import * as authActions from '../actionsTypes/auth';
import * as userActions from '../actionsTypes/user';
import * as services from '../../services/api/auth';
import {endInitializing} from './layout';

export const login = (email, password, setError, history) => (async dispatch => {
    try {
        const response = await services.login(email, password);

        localStorage.setItem('token', response.data.access_token)

        dispatch({ type: authActions.AUTH_SUCCESS });
        dispatch({ type: userActions.GET_USER_DATA, payload: response.data });
    } catch (error) {
        if (error.response && error.response.data) {
            setError(error.response.data.message);
        }
    }
})

export const logout = (history) => (async dispatch => {
    try {
        await services.logout();
        localStorage.removeItem('token');
        dispatch({ type: authActions.LOG_OUT_SUCCESS });
        history.push('/');
    } catch (error) {
    }
})

export const getCurrentUser = () => (async dispatch => {
    try {
        const response = await services.getProfile();

        dispatch({ type: authActions.AUTH_SUCCESS });
        dispatch({ type: userActions.GET_USER_DATA, payload: response.data });
    } catch (error) {

    }finally {
        dispatch(endInitializing());
    }
})