import * as actions from '../actionsTypes/layout'


export default (state = { initializing: true, loading: false }, { type, payload }) => {
    switch (type) {
        case actions.END_INITIALIZING : return {...state, initializing: false};
        case actions.END_LOADING : return {...state, loading: false};
        case actions.START_LOADING : return {...state, loading: true};
        default: return state;
    }
};