import React from 'react';
import { Button } from 'react-materialize';
import { deleteBanner } from '../../redux/actions'
import { connect } from 'react-redux';

function BannerDelete({history,banner,onDelete}) {

    return (
       <Button className="red" onClick={()=>{onDelete(banner)}}>Eliminar</Button>
    );    
}

const mapDispatchToProps = dispatch => ({
    onDelete: (banner,history) => dispatch(deleteBanner(banner,history))
});

export default connect(null, mapDispatchToProps)(BannerDelete);
