import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch'
import { tableDataExtractionStrategy } from '../../services/tables';
import { tableService } from './dataStructures';

const useTable = () => {

	const [{ data, isLoading, isError }, setRequest] = useFetch(tableService(1), null, tableDataExtractionStrategy)
	const [formatedData, setFormatedData] = useState([])
	const [paginationData, setPaginationData] = useState({})

	const onPaginate = (page) => {
		setRequest(tableService(page))
	}

	useEffect(() => {
		if (data) {
			setFormatedData(data.formatedData)
			setPaginationData(data.paginationData)
		}
	}, [data])

	return { formatedData, paginationData, isLoading, isError, onPaginate };
};

export default useTable;
