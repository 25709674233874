import React from 'react'
import { Icon, Pagination } from 'react-materialize';

const _Pagination = ({ total, onPaginate, currentPage }) => {
    const onChangePaginate = num => {
        if (num !== 0 && num <= total) {
            onPaginate(num);
        }
    };
    return (
        <Pagination onSelect={onChangePaginate} activePage={currentPage} items={total} leftBtn={<Icon>chevron_left</Icon>}
            maxButtons={8}
            rightBtn={<Icon>chevron_right</Icon>} />
    )
}

export default _Pagination
