import React from 'react';
import { connect } from 'react-redux';

import Navigtion from './Navigation/Navigation';

const Layout = ({ children, routes }) => {
    return (
        <>
            <Navigtion routes={routes} />
            <main>
                {children}
            </main>
        </>
    );
}

const mapStateToProps = state => ({

});

const mapdispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapdispatchToProps)(Layout);