
import * as services from '../../services/api/Banners';
import * as bannerActions from '../actionsTypes/banner'

export const getListBanner = (setError, history) => (async dispatch => {
    try {
        const response = await services.getAllBanners();
        dispatch({ type: bannerActions.GET_ALL_BANNER_DATA, payload: response.data });
    }catch (error) {
        if (error.response && error.response.data) {
            setError(error.response.data.message);
        }
    }
})

export const getBannerById = (id,setError) => (async dispatch => {
    try {
        const response = await services.getBannerById(id);
        dispatch({ type: bannerActions.GET_BANNER_DATA, payload: response.data });
    }catch (error) {
        if (error.response && error.response.data) {
            setError(error.response.data.message);
        }
    }
})

export const deleteBanner = (banner,history) => (async dispatch => {
    try {
        await services.deleteBanner(banner);
        dispatch({ type: bannerActions.DELETE_BANNER, payload: {banner} });
        history.push('/home');
    }catch (error) {
    }
})