import * as actions from '../actionsTypes/layout'

export const startLoading =  () =>({
    type: actions.START_LOADING
});

export const endLoading =  () =>({
    type: actions.END_LOADING
});

export const endInitializing =  () =>({
    type: actions.END_INITIALIZING
});