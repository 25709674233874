import React from 'react';
import { Button } from 'react-materialize';
import { useHistory } from 'react-router-dom';
import paths from '../../constants/paths';
import Pagination from './Pagination'

import Loader from '../common/Loader';
import Table from './Table';
import useTable from './useTable';

const Notifications = () => {
	const { formatedData, paginationData, isLoading, isError, onPaginate } = useTable();

	const history = useHistory();
	return (
		<div className='container p-3'>
			<Button
				style={{ display: 'block', margin: '1em' }}
				onClick={() => {
					history.push(paths.notificationsCreate);
				}}>
				Enviar notificación
			</Button>
			{(!formatedData || isLoading || isError) && <Loader />}
			<Table tableData={formatedData} />
			<Pagination onPaginate={onPaginate} total={paginationData.total} currentPage={paginationData.currentPage} />
		</div>
	);
};

export default Notifications;
