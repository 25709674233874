import axios from './axiosInstance';


export const login =  function(email, password) {
  
    return axios.post('/auth/login', { email, password }, {
        headers: {
            'Accept': 'application/json'
        }
    });
}

export const logout=  function() {
    return axios.post('/auth/logout');
}

export const getProfile = function() {
    return axios.get('/auth/profile');
}