import React, { Fragment } from 'react'
import InputField from './InputField';


function RepeatPassword({errors, register, validate}) {
    
    return (
        <Fragment>
        <InputField 
            name="password"
            placeholder="Su clave"
            errors={errors}
            register={register} 
            required="Es necesario que ingrese una clave"
            type="password" 
            min={8} 
            minMessage='Su clave debe tener el menos 8 caracteres'
        ></InputField>
        <InputField 
            name="passwordRepeat"
            placeholder="Repita su clave"
            errors={errors}
            register={register} 
            required="Es necesario que repita su clave"
            type="password" 
            min={8} 
            minMessage='Su clave debe tener el menos 8 caracteres'
            validate={validate}
        ></InputField>
        </Fragment>
    );
}

export default RepeatPassword;