import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import {Icon , Button } from 'react-materialize';
import {postBanner} from '../../services/api/Banners'
import InputField from '../common/InputField';
import useErrors from '../Hooks/useErrors';
import Loader from '../common/Loader';
import ImagenField from '../common/ImagenField';

function BannerNew({history}) {
    const {register, handleSubmit, errors, setError } = useForm();
    const { handleErrors } = useErrors();
    const [loader, setLoader] = useState(false);
    const [image, setImage] = useState();


   const handleImageChange = (e) => {
          setImage(e.target.files[0])
      };

    const onSubmit = handleSubmit(async ( data ) => {
        setLoader(true) 
        try {
            let form_data = new FormData();
            form_data.append('file', image, image.name);
            form_data.append('title', data.title);
            form_data.append('description', data.description);
            await postBanner(form_data);
            history.push('/');
        } catch (error) {
            handleErrors(error, setError);
            setLoader(false)
        }
    });


    return (
        <section className="container">
           { loader && <Loader></Loader> }
        <div className="row">
        
        <form onSubmit={onSubmit} className="col s8 offset-s2">
        <InputField 
            name="title"
            placeholder="Titulo del banner"
            errors={errors}
            register={register} 
            required="El titulo es un campo obligatorio."
            min={5} 
            minMessage='Su titulo debe tener el menos 5 caracteres' 
        ></InputField>
        <InputField 
            name="description"
            placeholder="Su descripción"
            errors={errors}
            register={register} 
            required="Su descripción es un campo obligatorio"
            min={5} 
            minMessage='Su descripción debe tener el menos 5 caracteres' 
        ></InputField>
        <ImagenField handleImageChange={handleImageChange}></ImagenField>

    <Button
        node="button"
        style={{
        marginRight: '5px'
        }}
        waves="light"
    >Guardar
    <Icon right>
        send
    </Icon>
    </Button>
    <Button
        node="button"
        style={{
        marginRight: '5px'
        }}
        waves="light"
        onClick={()=>{history.push('/')}}
    >Cancelar
    <i className="material-icons right">cancel</i>
    </Button>
  { errors.general && <p>Se ha producido un error, por favor intete mas tarde</p>}
  </form>
</div>
</section>
    );    
}

export default BannerNew;