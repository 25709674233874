import React from 'react';
import TableRow from './TableRow';

const Table = ({ tableData }) => {

	return (
		<div>
			<table className='highlight'>
				<thead>
					<tr>
						<th>Titulo</th>
						<th>Contenido</th>
						<th>Fecha de creación</th>
					</tr>
				</thead>
				<tbody>{tableData?.length > 0 && tableData.map(data => <TableRow key={data.id} data={data} />)}</tbody>
			</table>
		</div>
	);
};

export default Table;
