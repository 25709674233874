import React from 'react'
import {TextInput } from 'react-materialize';

function ImagenField({ handleImageChange }) {
    
    return (<div className="input-field col s12">
        <TextInput
            id="image"
            label="Seleccione Archivo"
            type="file"
            accept="image/png, image/jpeg"  onChange={handleImageChange} required/>         
                  
    </div>
    )
}

export default ImagenField;