import React, { useState } from 'react';
import { Button } from 'react-materialize';
import useFetch from '../../hooks/useFetch';
import { postNotifications } from '../../services/api/notifications';
import Loader from '../common/Loader';

const CreateNotifications = () => {
	const [{ data, isLoading }, setRequest] = useFetch();
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [errors, setErrors] = useState({});

	const handleTitle = value => {
		if (value.length <= 200) {
			setTitle(value);
			setErrors(errors => ({ ...errors, title: null }));
		} else {
			setErrors(errors => ({ ...errors, title: 'Debe tener menos de 200 caracteres' }));
		}
	};

	const handleBody = value => {
		if (value.length <= 1024) {
			setBody(value);
			setErrors(errors => ({ ...errors, body: null }));
		} else {
			setErrors(errors => ({ ...errors, body: 'Debe tener menos de 1024 caracteres' }));
		}
	};

	const onSubmit = event => {
		event.preventDefault();
		if (title) {
			if (body) {
				setErrors({ body: null, title: null });
				setRequest(postNotifications({ body, title }));
			} else {
				setErrors(errors => ({ ...errors, body: 'Debe tener contenido' }));
			}
		} else {
			setErrors(errors => ({ ...errors, title: 'Debe tener titulo' }));
		}
	};

	return (
		<div className='container'>
			{isLoading && <Loader />}
			<form onSubmit={onSubmit} className='row'>
				<div className='input-field col s12'>
					<input id="title" name="title" value={title} onChange={e => handleTitle(e.target.value)} type='text' className='validate' />
					<label htmlFor='title'>Titulo</label>
					{errors.title && (
						<span className='helper-text' style={{ color: 'red' }}>
							{errors.title}
						</span>
					)}
				</div>
				<div className='input-field col s12'>
					<textarea id="body" name="body" value={body} onChange={e => handleBody(e.target.value)} type='text' className='materialize-textarea' />
					<label htmlFor='body'>Contenido</label>
					{errors.body && (
						<span className='helper-text' style={{ color: 'red' }}>
							{errors.body}
						</span>
					)}
				</div>
				{data && (
					<span className='helper-text' style={{ color: 'green' }}>
						Notificacion Creada Correctamente
					</span>
				)}
				<div className='col s12'>
					<Button node='button' waves='light' disabled={errors.body || errors.title || isLoading}>
						Enviar
					</Button>
				</div>
			</form>
		</div>
	);
};

export default CreateNotifications;
