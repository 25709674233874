import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getListBanner } from '../../redux/actions';
import banner from '../../redux/reducers/banner';

import BannerDelete from '../Banner/BannerDelete'
import { Table, Button } from 'react-materialize';
import paths from '../../constants/paths';

function Home({ getListBanners, banners, history }) {

    const [, setError] = useState('');

    useEffect(() => {
        getListBanners(setError);
    }, [getListBanners]);


    const showRows = (banners) => {
        return (
            <tbody>
                {banners.map(function (row) {
                    return showRow(row)
                })}
            </tbody>
        );
    }

    const showRow = (banner) => {
        if (Boolean(banner)) {
            return (<tr>
                <td>{banner.title}</td>
                <td>
                    <img alt={banner.title} height="100" width="100" src={banner.path}></img>
                </td>
                <td>
                    <BannerDelete banner={banner} />
                </td>
            </tr>)
        }
    };

    const BannersExist = () => {
        return Boolean(banner) && Array.isArray(banners) && banners.length > 0;
    }

    return (
        <div>
            <Button style={{ display: "block", margin: "1em" }} onClick={() => { history.push(paths.bannernew) }}>Nuevo banner</Button>
            <br />
            {BannersExist() ?
                <Table centered={true}>
                    <thead>
                        <tr>
                            <th>Titulo</th>
                            <th>Imagen</th>
                            <th>Accion</th>
                        </tr>
                    </thead>
                    {showRows(banners)}
                </Table> :
                'No hay banners cargados'
            }
        </div>
    )
}

const mapStateToProps = state => ({
    banners: state.banner
})

const mapDispatchToProps = dispatch => ({
    getListBanners: (setError) => dispatch(getListBanner(setError))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);