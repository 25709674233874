import React, { useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from './routes';

function Router({auth}) {
    const buildRoutes = useCallback(function () {
        const routesKey = auth ? 'authenticated' : 'unauthenticated';
        const routesArray = routes[routesKey].map( route => <Route {...route} />);

        return routesArray;
    }, [auth]);

    return (
        <Switch>
            {buildRoutes()}
        </Switch>
    )
}

export default Router;