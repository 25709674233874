import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getBannerById } from '../../redux/actions';
import InputField from '../common/InputField';

import Loader from '../common/Loader';
import ImagenField from '../common/ImagenField';
import { useForm } from 'react-hook-form';
import { Button } from 'react-materialize';

function BannerEdit({match, getBannerById,banner}) {
    const {register, handleSubmit, errors,  setError } = useForm();
  
    const [loader, setLoader] = useState(true);
    const [, setImage] = useState();


    const handleImageChange = (e) => {
           setImage(e.target.files[0])
       };

    useEffect(() =>{
    getBannerById(match.params.id, setError);
    setLoader(false);
   },[getBannerById,match.params.id, setError ])
   
   
   const onSubmit = async(data) => {   
       
   };
   
   return (<section className="container">
            { loader && <Loader></Loader> }
            <div className="row">
            
            <form onSubmit={handleSubmit(onSubmit)} className="col s8 offset-s2">
            <InputField 
                name="title"
                value={banner.title}
                placeholder="Titulo del banner"
                errors={errors}
                register={register} 
                required="El titulo es un campo obligatorio."
                min={5} 
                minMessage='Su titulo debe tener el menos 5 caracteres' 
            ></InputField>
            <InputField 
                name="description"
                placeholder="Su descripción"
                errors={errors}
                value={banner.description}
                register={register} 
                required="Su descripción es un campo obligatorio"
                min={5} 
                minMessage='Su descripción debe tener el menos 5 caracteres' 
            ></InputField>
            <ImagenField handleImageChange={handleImageChange}></ImagenField>

        <Button
        node="button"
        style={{
        marginRight: '5px'
        }}
        waves="light"
        onClick={() => {setLoader(true)}}
        >Guardar</Button>
        { errors.general && <p>Se ha producido un error, por favor intete mas tarde</p>}
        </form>
        </div>
        </section>
        );   
}

const mapStateToProps = state => ({
    banner: state.banner
  })

const mapDispatchToProps = dispatch => ({
    getBannerById: (id,setError) => dispatch(getBannerById(id,setError))
});

export default connect(mapStateToProps, mapDispatchToProps)(BannerEdit);