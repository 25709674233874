import React, { useState } from 'react';
import { useForm } from 'react-hook-form'
import { Button } from 'react-materialize';
import {postUser} from '../../services/api/Users'
import InputField from '../common/InputField';
import RepeatPassword from '../common/RepeatPassword';
import useErrors from '../Hooks/useErrors';
import Loader from '../common/Loader';

function Registration({history}) {
    const {register, handleSubmit, errors, watch, setError } = useForm();
    const { handleErrors } = useErrors();
    const [loader, setLoader] = useState(false);

    const onSubmit = async(data) => {   
        
        if(data.password){  
                   
        try {
            await postUser(data);
            history.push('/success_registration');
        } catch (error) {
            handleErrors(error, setError);
            setLoader(false)
        }
    }
     setLoader(false)   
    };

    return (
        <section className="container">
           { loader && <Loader></Loader> }
        <div className="row">
        
        <form onSubmit={handleSubmit(onSubmit)} className="col s8 offset-s2">
        <InputField 
            name="username"
            placeholder="Su username"
            errors={errors}
            register={register} 
            required="Su nombre de usuario es un campo obligatorio"
            min={5} 
            minMessage='Su nombre de usuarios debe tener el menos 5 caracteres' 
        ></InputField>
        <InputField 
            name="name"
            placeholder="Su nombre"
            errors={errors}
            register={register} 
            required="Su nombre es un campo obligatorio"
            min={5} 
            minMessage='Su nombre debe tener el menos 5 caracteres' 
        ></InputField>
        <InputField 
            name="email"
            placeholder="Su email"
            errors={errors}
            register={register} 
            required="Su email es un campo obligatorio"
            type="email" 
        ></InputField>
        <RepeatPassword
        errors={errors}
        register={register} 
        validate={(value) => value === watch('password') || "Las claves no coinciden"}
        ></RepeatPassword>
        

<Button
    node="button"
    style={{
      marginRight: '5px'
    }}
    waves="light"
    onClick={() => {setLoader(true)}}
  >Registrarme!</Button>
  { errors.general && <p>Se ha producido un error, por favor intete mas tarde</p>}
  </form>
</div>
</section>
    );    
}

export default Registration;